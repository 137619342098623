/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 17c-.28 0-.5-.22-.5-.5v-5c0-.28.22-.5.5-.5s.5.22.5.5v5c0 .28-.22.5-.5.5zM12 8c-.12 0-.23-.04-.32-.12a.484.484 0 01-.05-.7c.18-.22.5-.24.71-.06.21.18.23.5.05.71-.1.12-.24.18-.39.18z" />
		<path d="M12 22.5C6.21 22.5 1.5 17.79 1.5 12S6.21 1.5 12 1.5 22.5 6.21 22.5 12 17.79 22.5 12 22.5zm0-20c-5.24 0-9.5 4.26-9.5 9.5s4.26 9.5 9.5 9.5 9.5-4.26 9.5-9.5-4.26-9.5-9.5-9.5z" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
